<template>
  <div class="box">
    <div class="box2">
      <div class="box-title">
        任务趋势图
        <div class="date">
          <el-date-picker
            v-model="date"
            type="daterange"
            style="width: 335px;margin-right: 10px;"
            value-format="timestamp"
            size="mini"
            :clearable="false"
            @change="loadData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-radio-group v-model="dateType" @change="loadData" size="mini">
            <el-radio-button label="day">日</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="content">
        <div id="main3" class="main"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
export default {
  data() {
    return {
      dateType: "day",
      date: ['', '']
    };
  },
  methods: {
    setChart() {
      var myChart = echarts.init(document.getElementById("main3"));
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ['巡检次数', '点检次数', '检修次数', '润滑次数']
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: [],
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "巡检次数",
            type: "line",
            smooth: true,
            // stack: "总量",
            data: [],
          },
          {
            name: "点检次数",
            type: "line",
            smooth: true,
            // stack: "总量",
            data: [],
          },
          {
            name: "检修次数",
            type: "line",
            smooth: true,
            // stack: "总量",
            data: [],
          },
          {
            name: "润滑次数",
            type: "line",
            smooth: true,
            // stack: "总量",
            data: [],
          }
        ],
      };
      let xAxisData = []
      this.data.inspections.forEach(item => {
        xAxisData.push(item.date)
      })
      this.data.checks.forEach(item => {
        xAxisData.push(item.date)
      })
      this.data.recondition.forEach(item => {
        xAxisData.push(item.date)
      })
      this.data.lubrication.forEach(item => {
        xAxisData.push(item.date)
      })
      let x = []
      for(var i = 0; i < xAxisData.length; i++){
        if(x.indexOf(xAxisData[i]) == -1){
          x.push(xAxisData[i]);
        }
      }
      x.sort()
      option.xAxis.data = x;

      x.forEach(item => {
        option.series[0].data.push(0)
        option.series[1].data.push(0)
        option.series[2].data.push(0)
        option.series[3].data.push(0)
      })

      this.data.inspections.forEach(item => {
        let i = x.indexOf(item.date)
        option.series[0].data[i] = item.count
      })
      this.data.checks.forEach(item => {
        let i = x.indexOf(item.date)
        option.series[1].data[i] = item.count
      })
      this.data.recondition.forEach(item => {
        let i = x.indexOf(item.date)
        option.series[2].data[i] = item.count
      })
      this.data.lubrication.forEach(item => {
        let i = x.indexOf(item.date)
        option.series[3].data[i] = item.count
      })

      myChart.setOption(option);
    },
    loadData() {
      this.$ajax.post('dashboardTrend', {
        endDate: this.date[1],
        startDate: this.date[0],
        type: this.dateType // day  月就传month 
      }).then(res => {
        this.data = res.data
        this.setChart()
      })
    }
  },
  mounted() {
    this.date = [dayjs().subtract(7, 'd').valueOf(), dayjs().valueOf()]
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #202020;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
.box {
  border-bottom: 14px solid #FAFAFA;
  background: #FAFAFA;
  .box2 {
    border-radius: 10px;
    background: #FFF;
  }
  .content {
    padding: 0 16px;
    padding-bottom: 16px;
    .search {
      overflow: hidden;
    }
  }
  .date {
    float: right;
  }
}
</style>