<template>
  <div class="box1 number">
    <div class="box-title">数据总览</div>
    <div class="box-content">
      <div>
        <div class="title">巡视点总数</div>
        <div class="data"> {{ data.patrolCount ? data.patrolCount : 0 }}</div>
      </div>
      <div>
        <div class="title">巡视设备总数</div>
        <div class="data"> {{ data.patrolDeviceCount ? data.patrolDeviceCount : 0 }}</div>
      </div>
      <div>
        <div class="title">累计巡检总数</div>
        <div class="data"> {{ data.inspectionCount ? data.inspectionCount : 0 }}</div>
      </div>
      <div>
        <div class="title">累计点检总数</div>
        <div class="data"> {{ data.checkCount ? data.checkCount : 0 }}</div>
      </div>
      <div>
        <div class="title">报修设备总数</div>
        <div class="data"> {{ data.repairCount ? data.repairCount : 0 }}</div>
      </div>
      <div>
        <div class="title">报废设备总数</div>
        <div class="data">{{ data.scrapCount ? data.scrapCount : 0 }} </div>
      </div>
      <div>
        <div class="title">运维人员总数</div>
        <div class="data"> {{ data.staffCount ? data.staffCount : 0 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  methods: {
    loadData() {
      this.$ajax.get('dashboardCount').then(res => {
        this.data = res.data
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.box-title {
  color: #333;
  font-size: 16px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
}
.box1 {
  border-bottom: 14px solid #FAFAFA;
  .box-content {
    display: flex;
    padding-bottom: 10px;
    >div {
      flex: 1;
      text-align: center;
      padding: 16px;
      height: 76px;
      margin: 0 10px;
      color: #333;
      background: #f5f7fa;
      box-sizing: border-box;
    }
    .title {
      font-size: 12px;
      color: #999;
    }
    .data {
      font-size: 20px;
      margin-top: 8px;
    }
  }
  
}
</style>