<template>
  <div class="box">
    <box2 />
    <!-- <box1 /> -->
    <!-- <box4 /> -->
    <box5 />
    <box6 />
    <!-- <box7 /> -->
    <box3 />
  </div>
</template>

<script>
import box1 from './box1'
import box2 from './box2'
import box3 from './box3'
import box4 from './box4'
import box5 from './box5'
import box6 from './box6'
import box7 from './box7'
export default {
  components: {
    box1,
    box2,
    box3,
    box4,
    box5,
    box6,
    box7,
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 0 !important;
}
</style>