import { render, staticRenderFns } from "./box4.vue?vue&type=template&id=1d98bafa&scoped=true&"
import script from "./box4.vue?vue&type=script&lang=js&"
export * from "./box4.vue?vue&type=script&lang=js&"
import style0 from "./box4.vue?vue&type=style&index=0&id=1d98bafa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d98bafa",
  null
  
)

export default component.exports