<template>
  <div class="box">
    <el-row :gutter="14">
      <el-col :span="12">
        <div class="content">
          <div class="box-title">
            设备分布图
          </div>
          <div id="main5" class="main"></div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="content">
          <div class="box-title">
            巡视设备占比
          </div>
          <div id="main6_2" class="main"></div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="content">
          <div class="box-title">
            数据总览
          </div>
          <div class="box-content">
            <div>
              <div>
                <div class="title">巡视点总数</div>
                <div class="data"> {{ data3.patrolCount ? data3.patrolCount : 0 }}</div>
              </div>
              <div class="imgs bg-colors1 iconfont icon-shebeiguanli"></div>
            </div>
            <div>
              <div>
                <div class="title">巡视点设备总数</div>
                <div class="data"> {{ data3.patrolDeviceCount ? data3.patrolDeviceCount : 0 }}</div>
              </div>
              <div class="imgs bg-colors2 iconfont icon-shebeiguanli"></div>
            </div>
            <div>
              <div>
                <div class="title">累计巡检总数</div>
                <div class="data"> {{ data3.inspectionCount ? data3.inspectionCount : 0 }}</div>
              </div>
              <div class="imgs bg-colors3 iconfont icon-xunjian"></div>
            </div>
            <div>
              <div>
                <div class="title">累计点检总数</div>
                <div class="data"> {{ data3.checkCount ? data3.checkCount : 0 }}</div>
              </div>
              <div class="imgs bg-colors4 iconfont icon-tubiaozhizuomoban-133"></div>
            </div>
            <div>
              <div>
                <div class="title">累计检修总数</div>
                <div class="data"> {{ data3.reconditionCount ? data3.reconditionCount : 0 }}</div>
              </div>
              <div class="imgs bg-colors5 iconfont icon-jianxiujihua"></div>
            </div>
            <div>
              <div>
                <div class="title">累计润滑总数</div>
                <div class="data">{{ data3.lubricationCount ? data3.lubricationCount : 0 }} </div>
              </div>
              <div class="imgs bg-colors6 iconfont icon-zhouchengrunhuayou"></div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      data: {},
      data2: {},
      data3: {}
    };
  },
  methods: {
    setChart() {
      var myChart = echarts.init(document.getElementById("main5"));
      var option = {
        color: ['#3398DB'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [{
          type: 'bar',
          barWidth: 30,
          data: [],
          itemStyle: {
            normal: {
              color: function(params) {
                var colorList = [
                  '#FF7070','#9FE080','#FFDC60','#FF915A','#839be2',
                  '#e8927d','#9BCA63','#fbe183','#F3A43B','#7ED3F4',
                  '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
                  '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
                  '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
                  '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66',
                  
                  '#FF7070','#9FE080','#fbe183','#FF915A','#839be2',
                  '#e8927d','#9BCA63','#FAD860','#F3A43B','#7ED3F4',
                  '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
                  '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
                  '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
                  '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66'
                ];
                return colorList[params.dataIndex];
              }
            }
          }
        }]
      };
      this.data.forEach(item => {
        option.xAxis.data.push(item.facilityName)
        option.series[0].data.push(item.deviceCount)
      });
      myChart.setOption(option);
    },
    setChart2() {
      var myChart = echarts.init(document.getElementById("main6_2"));
      var option = option = {
          tooltip: {
              trigger: 'item',
              formatter: '数量: {c} <br/> 占比：{d}%'
              // {a} <br/>
          },
          legend: {
              orient: 'horizontal',
              // right: 10,
              y: 'bottom',
              data: []
          },
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      label: {
                          show: true,
                          fontSize: '30',
                          fontWeight: 'bold'
                      }
                  },
                  // labelLine: {
                  //     show: false
                  // },
                  data: [
                      // {value: 335, name: '直接访问'},
                  ]
              }
          ]
      };
      this.data2.forEach(item => {
        option.legend.data.push(item.archivesName)
        option.series[0].data.push({
          value: item.deviceCount,
          name: item.archivesName
        })
      });
      myChart.setOption(option);
    },
    // facilityDistribute
    loadData() {
      this.$ajax.get('facilityDistribute').then(res => {
        this.data = res.data
        this.setChart()
      })
    },
    loadData2() {
      this.$ajax.get('devicePercent').then(res => {
        this.data2 = res.data
        this.setChart2()
      })
    },
    loadData3() {
      this.$ajax.get('dashboardCount').then(res => {
        this.data3 = res.data
      })
    }
  },
  mounted() {
    this.loadData() //设备分布图
    this.loadData2() //巡视设备占比
    this.loadData3() //数据总览
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #202020;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
.box {
  border-bottom: 14px solid #FAFAFA;
  background: #FAFAFA;
  overflow: hidden;
  .content {
    background: #fff;
    border-radius: 10px;
    padding-bottom: 16px;
    .search {
      overflow: hidden;
    }
    .box-content {
      padding-bottom: 10px;
      >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        height: 78px;
        color: #333;
        box-sizing: border-box;
      }
      .imgs {
        width: 48px;
        height: 48px;
        background: rgb(71, 154, 202);
        border-radius: 5px;
        text-align: center;
        line-height: 48px;
        color: #FFF;
        font-size: 24px;
        overflow: hidden;
        position: relative;
      }
      .imgs:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform 0.3s, opacity 0.5s;
      }
      .imgs:active:after {
        transform: scale(0, 0);
        opacity: 0.6;
        transition: 0s;
      }
      .bg-colors1 { background-color: #FFDC60; }
      .bg-colors2 { background-color: #7ED3F4; }
      .bg-colors3 { background-color: #FF915A; }
      .bg-colors4 { background-color: #9FE080; }
      .bg-colors5 { background-color: #FF7070; }
      .bg-colors6 { background-color: #5C7BD9; }
      .title {
        font-size: 14px;
        color: #767779;
      }
      .data {
        font-size: 20px;
        margin-top: 8px;
        color: #202020;
      }
    }
  }
  .date {
    float: right;
  }
}
</style>