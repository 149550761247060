<template>
  <div class="box2">
    <div class="box2-box">
      <el-row>
        <el-col :span="10">
          <div class="content">
            <div class="box-title">设备分类</div>
            <div id="main7_1" class="main"></div>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="table-content">
            <el-table :data="data1" style="width: 100%">
              <el-table-column prop="deviceTypeName" label="分类"></el-table-column>
              <el-table-column prop="deviceCount" label="数量"></el-table-column>
              <el-table-column prop="percent" label="占比"></el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      tableData: [],
      data1: []
    };
  },
  methods: {
    setChart1() {
      var myChart = echarts.init(document.getElementById("main7_1"));
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: "占比",
            type: "pie",
            radius: "55%",
            // center: ["50%", "55%"],
            data: [
              // { value: 335, name: "直接访问" },
            ],
          },
        ],
      };
      this.data1.forEach(item => {
        // option.legend.data.push(item.patrolName)
        option.series[0].data.push({
          value: item.deviceCount,
          name: item.deviceTypeName
        })
      });
      myChart.setOption(option);
    },
    loadData1() {
      this.$ajax.get('deviceTypeRank').then(res => {
        this.data1 = res.data
        this.setChart1()
      })
    },
  },
  mounted() {
    this.loadData1()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #333;
  font-size: 16px;
  height: 50px;
  padding: 0 16px;
  line-height: 50px;
}
.box2 {
  background: #fff;
  border-bottom: 14px solid #FAFAFA;
  .box2-box {
    overflow: hidden;
    .content {
      background: #fff;
    }
    .table-content {
      padding: 0 20px;
      padding-top: 40px;
    }
    .data-box {
      display: flex;
      padding: 10px;
      box-sizing: border-box;
      background-color: #f5f7fa;
      margin-bottom: 20px;
      height: 80px;
    }
    .img-box {
      flex: 1;
    }
    .num-box {
      flex: 1;
    }
    .title {
      font-size: 14px;
      color: #999;
    }
    .data {
      line-height: 40px;
      font-size: 20px;
    }
  }
}
</style>