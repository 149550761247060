<template>
  <div class="box2">
    <div class="box-title">数据分析仪面板</div>
    <div class="box2-box">
      <el-row :gutter="14">
        <el-col :span="6">
          <div class="data-box">
            <div class="num-box">
              <div class="title">今日巡检设备数</div>
              <div class="data">{{data.inspectionInfo.deviceCount ? data.inspectionInfo.deviceCount : 0}}</div>
            </div>
            <div class="task-box">
              <div class="task-left">
                <div class="title">任务</div>
                <div class="t-label notDetect">未巡检：{{ data.inspectionInfo.taskUncheckCount ? data.inspectionInfo.taskUncheckCount : 0 }}</div>
                <div class="t-label inspected">已巡检：{{ data.inspectionInfo.taskCompleteCount ? data.inspectionInfo.taskCompleteCount : 0 }}</div>
                <div class="t-label missInspection">已漏检：{{ data.inspectionInfo.taskMissCount ? data.inspectionInfo.taskMissCount : 0 }}</div>
              </div>
              <div class="task-right">
                <div class="title">设备</div>
                <div class="t-label abnormal">异常：{{ data.inspectionInfo.deviceAbnormalCount ? data.inspectionInfo.deviceAbnormalCount : 0 }}</div>
                <div class="t-label normal">正常：{{ data.inspectionInfo.deviceNormal ? data.inspectionInfo.deviceNormal : 0 }}</div>
                <div class="t-label unknown">未知：{{ data.inspectionInfo.deviceMissCount ? data.inspectionInfo.deviceMissCount : 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="data-box">
            <div class="num-box">
              <div class="title">今日点检设备数</div>
              <div class="data">{{data.checkInfo.deviceCount ? data.checkInfo.deviceCount : 0}}</div>
            </div>
            <div class="task-box">
              <div class="task-left">
                <div class="title">任务</div>
                <div class="t-label notDetect">未点检：{{ data.checkInfo.taskUncheckCount ? data.checkInfo.taskUncheckCount : 0 }}</div>
                <div class="t-label inspected">已点检：{{ data.checkInfo.taskCompleteCount ? data.checkInfo.taskCompleteCount : 0 }}</div>
                <div class="t-label missInspection">已漏检：{{ data.checkInfo.taskMissCount ? data.checkInfo.taskMissCount : 0 }}</div>
              </div>
              <div class="task-right">
                <div class="title">设备</div>
                <div class="t-label abnormal">异常：{{ data.checkInfo.deviceAbnormalCount ? data.checkInfo.deviceAbnormalCount : 0 }}</div>
                <div class="t-label normal">正常：{{ data.checkInfo.deviceNormal ? data.checkInfo.deviceNormal : 0 }}</div>
                <div class="t-label unknown">未知：{{ data.checkInfo.deviceMissCount ? data.checkInfo.deviceMissCount : 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="data-box">
            <div class="num-box">
              <div class="title">今日检修设备数</div>
              <div class="data">{{data.reconditionInfo.deviceCount ? data.reconditionInfo.deviceCount : 0}}</div>
            </div>
            <div class="task-box">
              <div class="task-left">
                <div class="title">任务</div>
                <div class="t-label notDetect">未检修：{{ data.reconditionInfo.taskUncheckCount ? data.reconditionInfo.taskUncheckCount : 0 }}</div>
                <div class="t-label inspected">已检修：{{ data.reconditionInfo.examiningCount ? data.reconditionInfo.examiningCount : 0 }}</div>
                <div class="t-label missInspection">-</div>
              </div>
              <div class="task-right">
                <div class="title">累计结果</div>
                <div class="t-label abnormal">未解决：{{ data.reconditionInfo.incompleteCount ? data.reconditionInfo.incompleteCount : 0 }}</div>
                <div class="t-label normal">已解决：{{ data.reconditionInfo.completedCount ? data.reconditionInfo.completedCount : 0 }}</div>
                <div class="t-label missInspection">-</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="data-box">
            <div class="num-box">
              <div class="title">今日润滑设备数</div>
              <div class="data">{{data.lubricationInfo.deviceCount ? data.lubricationInfo.deviceCount : 0}}</div>
            </div>
            <div class="task-box">
              <div class="task-left">
                <div class="title">任务</div>
                <div class="t-label notDetect">未完成：{{ data.lubricationInfo.taskUncheckCount ? data.lubricationInfo.taskUncheckCount : 0 }}</div>
                <div class="t-label inspected">已完成：{{ data.lubricationInfo.taskCompleteCount ? data.lubricationInfo.taskCompleteCount : 0 }}</div>
                <div class="t-label missInspection">已遗漏：{{ data.lubricationInfo.taskMissCount ? data.lubricationInfo.taskMissCount : 0 }}</div>
              </div>
              <div class="task-right"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      data: {
        inspectionInfo: {},
        checkInfo: {},
        reconditionInfo: {},
        lubricationInfo: {}
      }
    }
  },
  methods: {
    setChart() {
      var myChart = echarts.init(document.getElementById("main2"));
      var option = {
        title: {
          text: "巡点检状况图",
          left: "center",
          top: 40
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}",
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            // center: ["50%", "55%"],
            radius: ['30%', '40%'],
            data: [
              { value: 1, name: `已完成率${this.data.completePercent ? this.data.completePercent : 0}%` },
              { value: 1, name: `正常率${this.data.normalPercent ? this.data.normalPercent : 0}%` },
              { value: 1, name: `异常率${this.data.abnormalPercent ? this.data.abnormalPercent : 0}%` },
              { value: 1, name: `未完成率${this.data.incompletePercent ? this.data.incompletePercent : 0}%` },
            ],
            label: {
            }
          },
        ],
      };
      myChart.setOption(option);
    },
    loadData() {
      this.$ajax.get('dashboardAnalysis').then(res => {
        this.data = res.data
      })
    }
  },
  mounted() {
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 440px;
  // background: #ccc;
}
.box-title {
  color: #202020;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
.center-bottom {
  overflow: hidden;
  font-size: 12px;
  color: #333;
  .left {
    float: left;
  }
  .rigth {
    float: right;
  }
}
.box2 {
  border-bottom: 14px solid #FAFAFA;
  background: #FAFAFA;
  .box2-box {
    overflow: hidden;
    .data-box {
      min-width: 350px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #FFF;
    }
    .num-box {
      flex: 1;
      border-bottom: 1px solid #e2e2e2;
    }
    .title {
      font-size: 14px;
      color: #767779;
    }
    .data {
      line-height: 50px;
      font-size: 36px;
      color: #202020;
      font-weight: bold;
    }
    .task-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 30px;
      .task-left {
        margin-right: 100px;
        flex: 1;
      }
      .task-right {
        flex: 1;
      }
      // 任务状态
      .t-label {
        padding: 0 10px;
        margin-top: 7px;
        font-size: 15px;
        line-height: 25px;
        text-align: left;
        border-radius: 5px;
      }
      .notDetect {//未巡检
        background-color:#fff5ec;
        color: #ff9f43; 
      }
      .inspected {// 已巡检
        background-color:#eff9fc;
        color: #0abde3; 
      }
      .missInspection {// 已漏检
        background-color:#f0f5f6;
        color: #636e72; 
      }
      // 设备状态
      .normal {// 正常
        background-color:#e8fcf6;
        color: #1dd1a1;
      }
      .abnormal {// 异常
        background-color:#ffeded;
        color: #ff6b6b; 
      }
      .unknown {// 未知
        background-color:#f0f5f6;
        color: #636e72; 
      }
    }
  }
}
</style>