<template>
  <div class="box2">
    <div class="box2-box">
      <el-row :gutter="14">
        <el-col :span="12">
          <div class="content">
            <div class="box-title">巡视点设备(Top5)</div>
            <div id="main6_1" class="main"></div>
          </div>
        </el-col>
        <!-- <el-col :span="12">
          <div class="content">
            <div class="box-title">巡视设备占比</div>
            <div id="main6_2" class="main"></div>
          </div>
        </el-col> -->
        <el-col :span="12">
          <div class="content">
            <div class="box-title">设备分类占比</div>
            <div class="table-content">
              <el-table :data="tableData" height="430" style="width: 100%">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="deviceTypeName" label="分类"></el-table-column>
                <el-table-column prop="deviceCount" label="数量"></el-table-column>
                <el-table-column prop="percent" label="占比"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      data1: {},
      data2: {},
      tableData: []
    };
  },
  methods: {
    setChart1() {
      var myChart = echarts.init(document.getElementById("main6_1"));
      var option = {
        color: ['#3398DB'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        // legend: {
        //   data: ["2011年", "2012年"],
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            // name: "2011年",
            type: "bar",
            barWidth: 30,
            data: [],
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    '#FF7070','#9FE080','#FFDC60','#FF915A','#839be2',
                    '#e8927d','#9BCA63','#fbe183','#F3A43B','#7ED3F4',
                    '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
                    '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
                    '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
                    '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66',
                    
                    '#FF7070','#9FE080','#fbe183','#FF915A','#839be2',
                    '#e8927d','#9BCA63','#FAD860','#F3A43B','#7ED3F4',
                    '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
                    '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
                    '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
                    '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66'
                  ];
                  return colorList[params.dataIndex];
                }
              }
            }
          }
        ],
      };
      this.data1.forEach(item => {
        option.yAxis.data.push(item.patrolName)
        option.series[0].data.push(item.deviceCount)
      });
      myChart.setOption(option);
    },
    setChart2() {
      var myChart = echarts.init(document.getElementById("main6_2"));
      var option = option = {
          tooltip: {
              trigger: 'item',
              formatter: '数量: {c} <br/> 占比：{d}%'
              // {a} <br/>
          },
          legend: {
              orient: 'vertical',
              right: 10,
              data: []
          },
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      label: {
                          show: true,
                          fontSize: '30',
                          fontWeight: 'bold'
                      }
                  },
                  // labelLine: {
                  //     show: false
                  // },
                  data: [
                      // {value: 335, name: '直接访问'},
                  ]
              }
          ]
      };
      this.data2.forEach(item => {
        option.legend.data.push(item.archivesName)
        option.series[0].data.push({
          value: item.deviceCount,
          name: item.archivesName
        })
      });
      myChart.setOption(option);
    },

    loadData1() {
      this.$ajax.get('deviceRank').then(res => {
        this.data1 = res.data
        this.setChart1()
      })
    },
    loadData2() {
      this.$ajax.get('devicePercent').then(res => {
        this.data2 = res.data
        this.setChart2()
      })
    },
    loadTableData() {
      this.$ajax.get('deviceTypeRank').then(res => {
        this.tableData = res.data
      })
    }
  },
  mounted() {
    this.loadData1()
    // this.loadData2()
    this.loadTableData()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #202020;
  font-size: 18px;
  height: 50px;
  padding: 0 16px;
  line-height: 50px;
  font-weight: bold;
}
.box2 {
  border-bottom: 14px solid #FAFAFA;
  background: #FAFAFA;
  .box2-box {
    overflow: hidden;
    .content {
      background: #fff;
      border-radius: 10px;
      .table-content {
        padding: 20px;
        height: 440px;
      }
    }
    .data-box {
      display: flex;
      padding: 10px;
      box-sizing: border-box;
      background-color: #f5f7fa;
      margin-bottom: 20px;
      height: 80px;
    }
    .img-box {
      flex: 1;
    }
    .num-box {
      flex: 1;
    }
    .title {
      font-size: 14px;
      color: #999;
    }
    .data {
      line-height: 40px;
      font-size: 20px;
    }
  }
}
</style>