<template>
  <div class="box2">
    <div class="box2-box">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="content">
            <div class="box-title">巡视点异常分布图</div>
            <div id="main4_1" class="main"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="content">
            <div class="box-title">设备异常分布图</div>
            <div id="main4_2" class="main"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      data1: {},
      data2: {}
    };
  },
  methods: {
    setChart1() {
      var myChart = echarts.init(document.getElementById("main4_1"));
      var option = {
        color: ['#3398DB'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "异常数",
            type: "bar",
            barWidth: 30,
            data: [],
          },
        ],
      };
      this.data1.forEach(item => {
        option.yAxis.data.push(item.patrolName)
        option.series[0].data.push(item.abnormalCount)
      });
      myChart.setOption(option);
    },
    setChart2() {
      var myChart = echarts.init(document.getElementById("main4_2"));
      var option = {
        color: ['#3398DB'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "异常数",
            type: "bar",
            barWidth: 30,
            data: [],
          }
        ],
      };
      this.data2.forEach(item => {
        option.yAxis.data.push(item.archivesName)
        option.series[0].data.push(item.abnormalCount)
      });
      myChart.setOption(option);
    },
    loadData1() {
      this.$ajax.get('patrolDistribute').then(res => {
        this.data1 = res.data
        this.setChart1()
      })
    },
    loadData2() {
      this.$ajax.get('deviceDistribute').then(res => {
        this.data2 = res.data
        this.setChart2()
      })
    }
  },
  mounted() {
    this.loadData1()
    this.loadData2()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #333;
  font-size: 16px;
  height: 50px;
  padding: 0 16px;
  line-height: 50px;
}
.box2 {
  border-bottom: 14px solid #FAFAFA;
  background: #FAFAFA;
  .box2-box {
    overflow: hidden;
    .content {
      background: #fff;
    }
    .data-box {
      display: flex;
      padding: 10px;
      box-sizing: border-box;
      background-color: #f5f7fa;
      margin-bottom: 20px;
      height: 80px;
    }
    .img-box {
      flex: 1;
    }
    .num-box {
      flex: 1;
    }
    .title {
      font-size: 14px;
      color: #999;
    }
    .data {
      line-height: 40px;
      font-size: 20px;
    }
  }
}
</style>